import React from 'react';
import { Chessboard } from 'react-chessboard';
import { Chess } from 'chess.js';
import useSound from 'use-sound';
import moveSound from '../../sounds/Move.mp3';
import captureSound from '../../sounds/Capture.mp3';

import styled from 'styled-components';

import { DataContext } from '../DataProvider';
import { UserContext } from '../UserProvider';

const ENDPOINT = process.env.REACT_APP_API_URL;

function Board() {
  const [chess] = React.useState(new Chess());
  const [positionFen, setPositionFen] = React.useState(chess.fen());
  const [validateMove, setValidateMove] = React.useState(true);
  const [playMoveSound] = useSound(moveSound);
  const [playCaptureSound] = useSound(captureSound);
  const [orientation, setOrientation] = React.useState('white');
  const [triggerFirstMove, setTriggerFirstMove] = React.useState(0);
  const [plans, setPlans] = React.useState([]);

  const [lastStateBeforePlanning, setLastStateBeforePlanning] = React.useState({
    lastFenBeforePlanning: chess.fen(),
    lastMoveBeforePlanning: null,
  });
  const [nextCorrectMove, setNextCorrectMove] = React.useState(null);

  const {
    resetAll,
    notes,
    toggle,
    tags,
    isPlanningMode,
    isMistake,
    setIsPlanningMode,
    setIsMistake,
    setPlayerSolvedExercise,
    setPlayerFailedExercise,
    setNumCorrectExercisePlan,
    exercisePlans,
    setExercisePlans,
    moveHistory,
    setMoveHistory,
    selectedMove,
    setSelectedMove,
  } = React.useContext(DataContext);
  const { token } = React.useContext(UserContext);

  React.useEffect(() => {
    const initialFen =
      'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';
    if (
      positionFen === initialFen &&
      orientation === 'black' &&
      tags !== '' &&
      toggle === 'training'
    ) {
      fetchNextMoveFromServer(initialFen, true, false);
    }
  }, [orientation, tags, triggerFirstMove]);

  const resetBoard = () => {
    const newGame = new Chess();
    setPositionFen(newGame.fen());
    chess.reset();
    setTriggerFirstMove((prev) => (prev + 1) % 100);
    setPlans([]);
    setIsPlanningMode(false);
    setIsMistake(false);
    resetAll();
  };

  const flipBoard = () => {
    setOrientation((prevOrientation) => {
      return prevOrientation === 'white' ? 'black' : 'white';
    });
  };

  const updateExercise = async (previous_fen, last_move, next_plans) => {
    const URL = ENDPOINT + 'edit-exercise';
    const BODY = {
      orientation,
      notes,
      previous_fen,
      last_move,
      is_mistake: isMistake ? 'True' : 'False',
      plans: next_plans,
      tags,
      exercise_type: 'standard_opening',
    };
    const request = new Request(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(BODY),
      timeout: 100000,
    });

    // const response = await fetch(request);
    // const json = await response.json();
    // console.log(`json=${JSON.stringify(json)}`);
    // return json;
  };

  const fetchNextMoveFromServer = async (
    fen,
    isOppMove,
    lastMoveWasCorrect
  ) => {
    const URL = ENDPOINT + 'training';
    const BODY = {
      fen,
      tag: tags,
    };
    const request = new Request(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(BODY),
      timeout: 100000,
    });

    const response = await fetch(request);
    const json = await response.json();
    if (json.message === 'success') {
      const data = json.data;
      const lastMove = data.LAST_MOVE;
      const [from, to] = lastMove.split('$');
      const moveData = {
        from: from,
        to: to,
        promotion: 'q',
      };
      if (isOppMove) {
        setTimeout(() => {
          let move = chess.move(moveData);
          if (move.flags.includes('c')) {
            playCaptureSound();
          } else {
            playMoveSound();
          }
          setPositionFen(chess.fen());
          fetchNextMoveFromServer(chess.fen(), false, lastMoveWasCorrect);
        }, 200);
      } else {
        setNextCorrectMove(moveData);
        const data = json.data;
        const exPlans = JSON.parse(data.PLANS);
        if (exPlans && exPlans.length > 0) {
          // console.log(`plans=${JSON.stringify(exPlans)}`);
          setExercisePlans(exPlans);
        } else {
          setExercisePlans([]);
        }
        // console.log(`json=${JSON.stringify(json)}`);
      }
    } else if (json.message === 'No results') {
      if (lastMoveWasCorrect) {
        console.log(`Exercise correct. What are ${orientation}'s plans?`);
        console.log(`exercisePlans=${JSON.stringify(exercisePlans)}`);
        setPlayerSolvedExercise(true);
        setIsPlanningMode(true);
      }
    }
    return json;
  };

  const verifyLastMoveWasCorrect = (moveData, nextCorrectMove) => {
    const keysA = Object.keys(moveData);
    const keysB = Object.keys(nextCorrectMove);
    const hasSameKeys =
      keysA.length === keysB.length &&
      keysA.every((key) => keysB.includes(key));
    const result =
      hasSameKeys &&
      keysA.every((key) => moveData[key] === nextCorrectMove[key]);
    return result;
  };

  const handlePieceDrop = (sourceSquare, targetSquare) => {
    // console.log(`validateMove=${validateMove}`);
    // console.log(`isPlanningMode=${isPlanningMode}`);
    try {
      // console.log('trying move');
      if (validateMove && !isPlanningMode) {
        const isRecordingMode = toggle === 'recording';
        const isTrainingMode = toggle !== 'recording';
        const moveData = {
          from: sourceSquare,
          to: targetSquare,
          promotion: 'q',
        };
        // console.log(`sourceSquare=${sourceSquare}`);
        // console.log(`targetSquare=${targetSquare}`);
        var lastMoveWasCorrect = false;
        if (isTrainingMode && nextCorrectMove) {
          lastMoveWasCorrect = verifyLastMoveWasCorrect(
            moveData,
            nextCorrectMove
          );

          if (!lastMoveWasCorrect) {
            setPlayerFailedExercise(true);
          }
        } else if (isTrainingMode) {
          lastMoveWasCorrect = true;
        }

        setLastStateBeforePlanning({
          lastFenBeforePlanning: chess.fen(),
          lastMoveBeforePlanning: moveData,
        });
        let move = chess.move(moveData);

        if (move) {
          const sideMoved = move.color === 'w' ? 'white' : 'black';
          console.log(`move=${JSON.stringify(move)}`);
          setMoveHistory((previous) => {
            return [...previous, move.san];
          });
          if (isRecordingMode) {
            console.log('updating with empty plans?');
            updateExercise(positionFen, moveData, []);
          }

          if (isTrainingMode) {
            if (sideMoved === orientation) {
              fetchNextMoveFromServer(chess.fen(), true, lastMoveWasCorrect);
            }
          }

          if (move.flags.includes('c')) {
            playCaptureSound();
          } else {
            playMoveSound();
          }

          setPositionFen(chess.fen());
          return true;
        } else {
          return false;
        }
      } else {
        // Planning Mode!
        if (targetSquare) {
          setPositionFen((prevFen) => {
            const newChess = new Chess(prevFen);

            const pieceType = newChess.get(sourceSquare)?.type;
            const pieceColor = newChess.get(sourceSquare)?.color;

            const isKing = newChess.get(sourceSquare)?.type === 'k';
            if (
              isKing &&
              Math.abs(
                sourceSquare.charCodeAt(0) - targetSquare.charCodeAt(0)
              ) === 2
            ) {
              const rank = sourceSquare[1]; // '1' for white, '8' for black
              const isKingside = targetSquare === `g${rank}`;
              const rookSourceSquare = isKingside ? `h${rank}` : `a${rank}`;
              const rookTargetSquare = isKingside ? `f${rank}` : `d${rank}`;

              // Move the rook for castling
              newChess.remove(rookSourceSquare);
              newChess.put(
                { type: 'r', color: newChess.get(sourceSquare).color },
                rookTargetSquare
              );
            }

            if (pieceType && pieceColor) {
              newChess.remove(sourceSquare);
              newChess.put(
                { type: pieceType, color: pieceColor },
                targetSquare
              );
            }
            setPlans((prevPlans) => {
              const newPlans = [...prevPlans];
              newPlans.push({ from: sourceSquare, to: targetSquare });
              if (toggle === 'recording') {
                console.log('updating with plans?');
                console.log(`newPlans=${JSON.stringify(newPlans)}`);
                const { lastFenBeforePlanning, lastMoveBeforePlanning } =
                  lastStateBeforePlanning;
                updateExercise(
                  lastFenBeforePlanning,
                  lastMoveBeforePlanning,
                  newPlans
                );
              }

              if (exercisePlans.length > 0) {
                let isPrefix = true;
                let isEqual = newPlans.length === exercisePlans.length;
                for (let i = 0; i < newPlans.length; i++) {
                  if (
                    !exercisePlans[i] ||
                    newPlans[i].from !== exercisePlans[i].from ||
                    newPlans[i].to !== exercisePlans[i].to
                  ) {
                    isPrefix = false;
                    break;
                  }
                }
                if (isPrefix) {
                  setNumCorrectExercisePlan(newPlans.length);
                }

                if (isEqual && isPrefix) {
                  console.log('equal');
                } else if (isPrefix) {
                  console.log('prefix');
                } else {
                  console.log('non-prefix');
                  setPlayerSolvedExercise(false);
                  setPlayerFailedExercise(true);
                }
              }

              return newPlans;
            });

            return newChess.fen();
          });
          return true;
        } else {
          setPositionFen((prevFen) => {
            const newChess = new Chess(prevFen);
            newChess.remove(sourceSquare);
            return newChess.fen();
          });
          return true;
        }
      }
    } catch (error) {
      console.log('Error making move:', error);
      return false;
    }
  };

  return (
    <>
      <Div>
        <Chessboard
          id='BasicBoard'
          onPieceDrop={handlePieceDrop}
          position={positionFen}
          dropOffBoardAction='snapback'
          boardOrientation={orientation}
          allowDragOutsideBoard={!validateMove}
        />
        <Button onClick={resetBoard}>Reset</Button>
        <Button onClick={flipBoard}>Flip</Button>
      </Div>
    </>
  );
}

export default Board;

const Button = styled.button`
  padding: 5px;
  background-color: lightgrey;
  margin: 2px;
  margin-top: 10px;
  margin-right: 3px;
  border-radius: 5px;
  font-weight: bold;
  border: 2px solid black;
`;

const Div = styled.div`
  margin-left: 37%;
  width: 60%;
`;
